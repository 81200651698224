export const faqs = [
  [
    "What is your most favorite tech stack ?",
    "Azure, Azure DevOps, .NET, .NET Core, C#, JavaScript, React, SQL Server.",
  ],
  [
    "What kind of projects do you enjoy most ?",
    "Web Applications. It is awesome when one application can be accessed anywhere any time with either zero or little restrictions.",
  ],
  ["What is next in your bucket list to learn ?", "Docker, Kubernetes"],
];
